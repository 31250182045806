<app-no-contract *ngIf="(this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)))"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"></app-no-contract>
<mat-card [class]="(this.context==='company' &&
(!this.company.ActiveContract ||
(this.company.hasDispatcherLicense && !this.company.ActiveContract.foreignStatus)) ? 'blurText' :'')"
          [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left push-right"
                 placeholder="{{'search_here'|translate}}"></td-search-box>
  <mat-divider></mat-divider>
  <ng-template tdLoading="products">
    <ng-container *ngFor="let type of types">
      <table class="mat-clickable" td-data-table>
        <thead>
        <tr td-data-table-column-row>
          <th (sortChange)="sort($event)"
              *ngFor="let c of columns"
              [active]="(sortBy == c.name)"
              [name]="c.name"
              [numeric]="c.numeric"
              [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
              [sortable]="c.sortable"
              td-data-table-column>
            {{ c.label | translate | ucFirst}}
          </th>
        </tr>
        </thead>
        <tbody class="noselect clickable">
        <tr *ngFor="let item of filteredData[type]" td-data-table-row (click)="openEdit($event,item.id)">
          <td *ngFor="let c of columns"
              [numeric]="item.numeric"
              td-data-table-cell>
                <span [ngSwitch]="c.name">
                  <span *ngSwitchDefault>
                    <span *ngIf="item[c.name]">{{ item[c.name] | ucFirst }}</span>
                  </span>
                </span>
          </td>
          <td class="handle-cell noselect">
            <mat-icon class="handle">drag_handle</mat-icon>
          </td>
        </tr>
        </tbody>
      </table>

      <td-paging-bar #pagingBar (change)="page($event)" [pageSize]="100"
                     [total]="(paging[type] ? paging[type].total : 0)">
        <span hide-xs td-paging-bar-label>{{ 'rows_p_page'|translate }}:</span>
        {{ pagingBar.range }}
        <span hide-xs>of {{ pagingBar.total }}</span>
      </td-paging-bar>
    </ng-container>
  </ng-template>
</mat-card>
