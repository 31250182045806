import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UpsertComponent} from '../../../../../global/upsert/upsert.component';
import {Company} from '../../../../../../models/company';
import moment from 'moment/moment';
import {FormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdLoadingService} from '@covalent/core/loading';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../../../../../services/navigation.service';
import {Title} from '@angular/platform-browser';
import {TdDialogService} from '@covalent/core/dialogs';
import {UtilityService} from '../../../../../../services/utility.service';
import {ucFirst} from '../../../../../../pipes/uc-first.pipe';
import {environment} from '../../../../../../../environments/environment';
import {JobMeta} from '../../../../../../models/job-meta';
import {JobMetaFieldService} from '../../../../../../services/job-meta-field.service';
import {getCurrencySymbol} from '@angular/common';
import {WebbookerService} from '../../../../../../services/webbooker.service';
import {DaAppInstallService} from '../../../../../../services/da-app-install.service';

@Component({
  selector: 'app-job-meta-field-upsert',
  templateUrl: './job-meta-field-upsert.component.html',
  styleUrls: ['./job-meta-field-upsert.component.scss']
})
export class JobMetaFieldUpsertComponent extends UpsertComponent implements OnInit, AfterViewInit {
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
  files = [];
  loaderName = 'jobMetaField';
  modelName = 'job-meta-fields';
  list = 'jobMetaFields';
  translations: string[] = [];
  currentAction;
  id: string;
  companyId: string;
  currency: string;
  action: string;
  data: JobMeta = new JobMeta();
  company: Company;

  minDate = moment().format('YYYY-MM-DDTHH:mm');
  maxDate = moment().format('YYYY-MM-DDTHH:mm');
  maxStartDate = moment().add('1', 'month').format('YYYY-MM-DDTHH:mm');

  form: UntypedFormGroup;
  formErrors: any = {
    name: '',
  };
  link = true;
  validationMessages = {
    'name': {
      'fieldName': 'name',
      'required': 'form_validation_required',
    },
    'fieldType': {
      'fieldName': 'fieldType',
      'required': 'form_validation_required',
    },
  };
  fieldTypes: any = [
    'text',
    'number',
    'numberdropdown',
    'dropdown',
    'checkbox',
    'hidden',
  ];
  currencyDisplay: string;
  currencySymbol: string;
  totalFormat: string;
  private showMinMaxFields: boolean;
  private checkArrayFixed: any;

  constructor(public snackBar: MatSnackBar,
              private _loadingService: TdLoadingService,
              private _formBuilder: UntypedFormBuilder,
              private _route: ActivatedRoute,
              private _router: Router,
              private _snackbar: MatSnackBar,
              private _translateService: TranslateService,
              private _webbookerService: WebbookerService,
              private _daAppInstallService: DaAppInstallService,
              private _navigationService: NavigationService,
              private _titleService: Title,
              private _dialogService: TdDialogService,
              private _jobMetaFieldService: JobMetaFieldService) {
    super(_loadingService, _dialogService, _navigationService, _router, _snackbar, _jobMetaFieldService, _translateService, _route);
    this.loadErrorTranslations(_translateService);
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    const self = this;
    const {company} = this._route.parent.snapshot.data;
    this.currency = company.currency;
    this.company = company;
    UtilityService.setBrowserTimeStamp(this.company);

    this._route.parent.params.subscribe(parentParams => {
      this.companyId = parentParams['id'];
      this._route.params.subscribe(params => {
        this.id = params['id'];
        this.action = params['action'] ? params['action'] : params['id'];

        _translateService.get([
          `${this.action}_jobMetaField`,
          'jobMetaField_archived',
          'jobMetaField_published',
          'jobMetaField_added_message',
          'jobMetaField_added_title',
          'jobMetaField_updated_message',
          'invalid_image_extention',
          'job_field_updated_message',
          'delete_x',
          'message_delete_x',
          'x_deleted'
        ]).subscribe((translations: any) => {
          self._navigationService.setSplitLayoutButtons([
            {
              'icon': 'list',
              'tooltip': self.translations['jobMetaFields'],
              'link': `/groups/${self.companyId}/${self.list}/`
            }
          ]);

          this.translations = translations;
          this.translations['updated_message'] = translations['job_field_updated_message'];
          this._titleService.setTitle(ucFirst(_translateService.instant(`${self.action}_job_meta_title`)) + environment.windowTitleSuffix);
          this._navigationService.setBodyTitle(ucFirst(_translateService.instant(`${self.action}_job_meta_title`)));
        });

        if (this.action === 'edit') {
          this.loadData();
        }
      });
    });
  }

  ngOnInit(): void {
    this.conditionalFormatting();
  }

  initForm() {
    /**
     * Set up our form
     */
    this.form = this._formBuilder.group({
      name: [this.data.name, [Validators.required]],
      fieldType: [this.data.fieldType, []],
      color: [(this.data.color ? this.data.color : '#000000'), []],
      placeholder: [this.data.placeholder, [Validators.required]],
      helptext: [this.data.helptext, [Validators.required]],
      min: [(this.data.min  ? this.data.min : 0), []],
      max: [(this.data.max  ? this.data.max : 0), []],
      surcharge: [this.data.surcharge, []],
      surchargeReturn: [this.data.surchargeReturn, []],
      hiddenValue: [this.data.hiddenValue, []],
      options: this._formBuilder.array([])
    });
    this.currencyDisplay = getCurrencySymbol(this.company.currency, 'narrow');
  }

  /**
   * Getters for option fields array
   */
  get optionFields(): FormArray {
    return this.form.get('options') as FormArray;
  }

  /**
   * Handle field type change
   */
  onFieldTypeChange(value: string): void {
    if (value === 'dropdown') {
      this.addOption(); // Ensure at least one field is present
    } else {
      this.optionFields.clear();
    }
  }

  /**
   * Add a new dropdown option
   */
  addOption(): void {
    this.form.controls['options']['controls'].push(this._formBuilder.group({
      label: ['', Validators.required],
      surcharge: [0, Validators.min(0)],
      surchargeReturn: [0, Validators.min(0)]
    }));
  }

  /**
   * Remove an option from dropdown list
   */
  removeOption(index: number): void {
    this.optionFields.removeAt(index);
  }

  conditionalFormatting() {
  }

  loadData(): void {
    // console.log(`[jobMetaFieldUpsertComponent.loadData]: `);
    this._jobMetaFieldService.get(this.id, {}).subscribe((jobMeta: JobMeta) => {
      this.checkArrayFixed = jobMeta.DaAppInstalls;
      this.data = jobMeta;

      this.initForm();
      this._loadingService.resolve(this.loaderName);
    }, error => {
      console.error(error);
      this._loadingService.resolve(this.loaderName);
    })
  }

  updateCheckArrayFixed(e) {
    this.checkArrayFixed = e.checkArrayFixed;
    if (!e.loading) {
      this.form.markAsDirty();
    }
  }
  valOrZero(input) {
    if (!input.value) {
      input.value = 0.00;
    }
    input.value = parseFloat(input.value).toFixed(2);
  }

  inputFocused(event) {
    event.target.select();
  }

  saveDataMapping(saveData) {
    saveData.DaAppInstalls = this.checkArrayFixed.filter(x => x);
    delete saveData.companyId;
    return saveData;
  }

  ngAfterViewInit(): void {
    if (document.getElementById('surcharge')) {
      this.valOrZero(document.getElementById('surcharge'));
      this.valOrZero(document.getElementById('surchargeReturn'));
    } else {
      setTimeout(() => {
        this.ngAfterViewInit();
      }, 500)
    }
  }

  updateMainColor(color) {
    this.form.controls['color'].setValue(color);
  }

  saveCompleted(result: any) {
    /**
     * Get the daApps, and see of there are any webbookers
     */
    const self = this;

    self._daAppInstallService.getAll({'where': {companyId: this.company.id, id: {'inq': this.checkArrayFixed}}}, 'company')
      .subscribe((webbookers: any[]) => {
        const webbookerFormIds = webbookers.filter((b) => b.formId).map((b) => b.formId);
        if (webbookerFormIds.length > 0) {
          webbookerFormIds.forEach((webbookerId) => {
            self._webbookerService.get(webbookerId)
              .subscribe((webbooker) => {
                /**
                 * Merge current fields with this new field, or replace if the same field is already here
                 */
                const alreadyHasField = webbooker.config.parts[0].fields.find((field) => field.id === this.data.id);

                if (alreadyHasField) {
                  webbooker.config.parts[0].fields = webbooker.config.parts[0].fields.map((field) => {
                    if (field.id === this.data.id) {
                      return this.mapToWebBookerField(this.data);
                    } else {
                      return field;
                    }
                  });
                } else {
                  this.data.id = result.id;
                  webbooker.config.parts[0].fields.push(this.mapToWebBookerField(this.data));
                }
                self._webbookerService.update(webbooker.id, webbooker).subscribe(() => {

                }, error => {
                  self._loadingService.resolve(this.loaderName);
                });
              });
          })
        }
      });
  }

  mapToWebBookerField(data) {
    /**
     * {
     *                         "type": "select",
     *                         "label": "passengers_field_label",
     *                         "property": "passengerCount",
     *                         "helpText": "passenger_count_field_help_text",
     *                         "quotationField": true,
     *                         "default": 1,
     *                         "values": [
     *                             {
     *                                 "value": 1,
     *                                 "label": "passengers_field_value_singular"
     *                             },
     *                             {
     *                                 "value": 2,
     *                                 "label": "passengers_field_value_plural"
     *                             },
     *                             {
     *                                 "value": 3,
     *                                 "label": "passengers_field_value_plural"
     *                             },
     *                             {
     *                                 "value": 4,
     *                                 "label": "passengers_field_value_plural"
     *                             }
     *                         ]
     *                     }
     */

    let webbookerField = {
      id: data.id,
      type: data.fieldType,
      label: data.placeholder,
      min: data.min,
      max: data.max,
      property: data.name.toLowerCase().replace(' ', '_'),
      helpText: data.helptext,
      quotationField: false,
      product: null
    };

    if(data.surcharge) {
      /**
       * "product": {
       *       "type": "global",
       *         "mode": "add",
       *         "amount": 2500,
       *         "returnAmount": 2500,
       *         "taxPercentage": 0
       *     }
       */
      webbookerField.product = {
        type: 'global',
        mode: 'add',
        amount: data.surcharge*100,
        returnAmount: data.surchargeReturn*100,
        taxPercentage: 0
      }
    }
    return webbookerField;
  }
}
