import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {DaAppInstall} from '../../../../../../models/da-app-install';
import {Company} from '../../../../../../models/company';
import {DaAppInstallService} from '../../../../../../services/da-app-install.service';
import {PricingRuleService} from '../../../../../../services/tps/pricing-rule.service';
import {DiscountService} from '../../../../../../services/tps/discount.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {UtilityService} from '../../../../../../services/utility.service';
import {environment} from '../../../../../../../environments/environment';
import {RuleLink} from '../../../../../../models/rule';
import {JobMetaFieldService} from '../../../../../../services/job-meta-field.service';
import {JobMeta} from '../../../../../../models/job-meta';

@Component({
  selector: 'app-jobmetafield-connected-dapps',
  templateUrl: './jobmetafield-connected-dapps.component.html',
  styleUrls: ['./jobmetafield-connected-dapps.component.scss']
})
export class JobmetafieldConnectedDappsComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() jobMetaField: JobMeta;
  @Input() checkArrayFixed = [];
  @Input() translations: string[];
  @Output() updateCheckArrayFixed = new EventEmitter<any>();
  daAppInstalls: DaAppInstall[] = [];
  company: Company;
  loading = true;
  constructor(
    private _daAppInstallService: DaAppInstallService,
    private _jobMetaFieldService: JobMetaFieldService,
    private _translationService: TranslateService,
    private _route: ActivatedRoute
  ) {
    const {company} = this._route.parent.snapshot.data;
    UtilityService.setBrowserTimeStamp(company);
    this.company = company;
  }
  ngOnInit(): void {
    const self = this;
    if (this.company) {
      this._daAppInstallService.getAll({'where': {companyId: this.company.id}, 'include': ['DaApp']}, 'company')
        .subscribe((daApps) => {
          /**
           * Sort by type Dispatch, booking
           */
          self.daAppInstalls = daApps.sort((a, b) => {
            if (a.DaApp.type < b.DaApp.type) {
              return -1;
            }
            if (a.DaApp.name > b.DaApp.type) {
              return 1;
            }
            return 0;
          });

          self.loading = false;
        });
    }
  }

  onCheckboxChangeFixed(e) {
    if(!this.checkArrayFixed) {
      this.checkArrayFixed = [];
    }
    if (e.checked) {
      this.checkArrayFixed.push(e.source.value);
    } else {
      this.checkArrayFixed.forEach((item: string, i: number) => {
        if (item === e.source.value) {
          delete this.checkArrayFixed[i];
          return;
        }
      });
    }
    if (!this.loading) {
      this.updateCheckArrayFixed.emit({checkArrayFixed: this.checkArrayFixed, loading: this.loading});
    }
  }

  getDaAppName(daApp): string {
    const translation = this._translationService.instant(`daapp_name_${daApp.name}`);
    return (translation !== `daapp_name_${daApp.name}` ? translation : daApp.name);
  }
}
