import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {CollectionComponent} from '../../../global/collection/collection.component';
import {Company} from '../../../../models/company';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdDialogService} from '@covalent/core/dialogs';
import {Title} from '@angular/platform-browser';
import {UtilityService} from '../../../../services/utility.service';
import {environment} from '../../../../../environments/environment';
import {MessageCenter} from '../../../../models/message-center';
import moment from 'moment';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {CompanyService} from '../../../../services/company.service';
import {JobMetaFieldService} from '../../../../services/job-meta-field.service';
import {JobMeta} from '../../../../models/job-meta';

@Component({
  selector: 'app-job-meta-fields',
  templateUrl: './job-meta-fields.component.html',
  styleUrls: ['./job-meta-fields.component.scss']
})
export class JobMetaFieldsComponent extends CollectionComponent implements OnInit, OnDestroy {
  loaderName = 'job-meta-fields';
  modelName = 'job-meta-fields';
  company: Company;
  settingsForm: UntypedFormGroup;
  columns = [
    {name: 'name', label: 'field_name', sortable: true, active: true},
    {name: 'fieldType', label: 'type', sortable: true, active: true},
  ];
  selectFilter = {
    where: {
      companyId: ''
    }
  }

  constructor(private _translateService: TranslateService,
              protected injector: Injector,
              private _jobMetaFieldService: JobMetaFieldService,
              private _companyService: CompanyService,
              private _snackbar: MatSnackBar,
              private _formBuilder: UntypedFormBuilder,
              private _dialogService: TdDialogService,
              private _titleService: Title) {
    super(injector);
    const {company} = this._route.parent.snapshot.data;
    UtilityService.setBrowserTimeStamp(this.company);
    this.company = company;
    this.selectFilter.where.companyId = this.company.id;
    this.modelService = this._jobMetaFieldService;

    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    this._route.parent.params.subscribe(params => {
      this.companyId = params['id'];
    });

    _translateService.get(['meta_fields_page_title']).subscribe((translations) => {
      console.log(translations['meta_fields_page_title']);
      console.log(_translateService.instant('meta_fields_page_title' + environment.windowTitleSuffix));
      this._titleService.setTitle(translations['meta_fields_page_title'] + environment.windowTitleSuffix);
      this._navigationService.setBodyTitle(translations['meta_fields_page_title']);
    })

    this.settingsForm = this._formBuilder.group({
      sentDefaultConfirmation: [(this.company.settings.sentDefaultConfirmation !== 'disabled' ? this.company.settings.sentDefaultConfirmation : false), []],
      sentDefaultCompletion: [(this.company.settings.sentDefaultCompletion !== 'disabled' ? this.company.settings.sentDefaultCompletion : false), []],
      sentDefaultCancelation: [(this.company.settings.sentDefaultCancelation !== 'disabled' ? this.company.settings.sentDefaultCancelation : false), []],
      sentDefaultDriverAssigned: [(this.company.settings.sentDefaultDriverAssigned !== 'disabled' ? this.company.settings.sentDefaultDriverAssigned : false), []],
      smsSender: [(this.company.settings.smsSender ? this.company.settings.smsSender : this.company.name.substring(0, 10)), []],
    }, {validators: []});
  }

  setLink(name: string) {
    const self = this;
    if (!this.link) {
      return;
    }
    this._navigationService.setActionLink('job-meta-fields/add');
  }

  ngOnDestroy() {
    this._navigationService.setSplitLayoutButtons([]);
    this._navigationService.setActionFn(null);
  }
  openEdit($event, itemId) {
    if ($event && (
      ($event.target && $event.target.localName === 'mat-icon' || $event.target.localName === 'button') ||
      ($event.srcElement && $event.srcElement.localName === 'mat-icon' || $event.srcElement.localName === 'button')
    )) {
      return false;
    }
    this._router.navigate([`/groups/${this.companyId}/${this.modelName}/${itemId}/edit`]);
  }

  delete(id: string) {
    const self = this;
    /**
     * Confirm
     */
    self._dialogService.openConfirm({
      'message': self._translateService.instant('confirm_delete_job_automationrule'),
      'disableClose': false,
      'acceptButton': self._translateService.instant('delete'),
    }).afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          self._jobMetaFieldService.delete(id, 'company').subscribe((r) => {
            self._snackbar.open(this.translations['job_automationrule_deleted'], '', {duration: 3000});
            self.loadData();
          });
        }
      });
  }
}
