import { Injectable } from '@angular/core';
import {GlobalService} from './global.service';
import {HttpClient} from '@angular/common/http';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class JobMetaFieldService extends GlobalService {
  constructor(_http: HttpClient, _vault: CoolLocalStorage, _utilityService: UtilityService) {
    super(_http, _vault, _utilityService);
    this.setModel('companyFieldSettings');
  }

  getAppLinks(param: {include: {scope: {where: {companyId: string}}; relation: string}; where: {ruleableType: string; ruleableId: string; ruleId: string}}) {

  }
}
