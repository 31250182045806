import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyRoutingModule} from './company-routing.module';
import {CovalentDataTableModule} from '@covalent/core/data-table';
import {CovalentExpansionPanelModule} from '@covalent/core/expansion-panel';
import {CovalentLayoutModule} from '@covalent/core/layout';
import {CovalentLoadingModule} from '@covalent/core/loading';
import {CovalentMediaModule} from '@covalent/core/media';
import {CovalentMenuModule} from '@covalent/core/menu';
import {CovalentMessageModule} from '@covalent/core/message';
import {CovalentPagingModule} from '@covalent/core/paging';
import {CovalentSearchModule} from '@covalent/core/search';

import {NgxLoadingButtonsModule} from 'ngx-loading-buttons';

import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatStepperModule} from '@angular/material/stepper';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatRadioModule} from '@angular/material/radio';

import {DashboardComponent} from './dashboard/dashboard.component';
import {DispatchpanelComponent} from '../shared/dispatchpanel/dispatchpanel.component';
import {RideDetailsComponent} from './administration/rides/ride-details/ride-details.component';
import {DriverUpsertComponent} from './drivers/driver-upsert/driver-upsert.component';
import {AgmCoreModule, GoogleMapsAPIWrapper} from '@agm/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MomentModule} from 'angular2-moment';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DriverDetailsComponent} from './drivers/driver-details/driver-details.component';
import {WidgetsModule} from '../../widgets/widgets.module';
import {RideLogComponent} from './administration/rides/ride-log/ride-log.component';
import {CovalentMarkdownModule} from '@covalent/markdown';
import {SharedModule} from '../shared/shared.module';
import {RideDispatchLogComponent} from './administration/rides/ride-dispatch-log/ride-dispatch-log.component';
import {UcFirstPipeModule} from '../../pipes/uc-first.pipe';
import {RideDispatchLogMapComponent} from './administration/rides/ride-dispatch-log-map/ride-dispatch-log-map.component';
import {ProductsComponent} from './settings/products/products.component';
import {PricingComponent} from './settings/pricing/pricing.component';
import {ProductUpsertComponent} from './settings/products/parts/product-upsert/product-upsert.component';
import {CollectionComponent} from '../global/collection/collection.component';
import {PricingRulesUpsertComponent} from './settings/pricing/parts/pricing-rules-upsert/pricing-rules-upsert.component';
import {TopComponent} from './settings/pricing/parts/pricing-rules-upsert/parts/top.component';
import {TableComponent} from './settings/pricing/parts/pricing-rules-upsert/parts/table.component';
import {ColumnComponent} from './settings/pricing/parts/pricing-rules-upsert/parts/columns/column.component';
import {LocationColumnComponent} from './settings/pricing/parts/pricing-rules-upsert/parts/columns/location-column.component';
import {AppsComponent} from './settings/apps/apps.component';
import {AppsUpsertComponent} from './settings/apps/parts/apps-upsert/apps-upsert.component';
import {PricingDiscountsUpsertComponent} from './settings/pricing/parts/pricing-discounts-upsert/pricing-discounts-upsert.component';
import {TimeframesComponent} from './settings/pricing/parts/timeframes/timeframes.component';
import {DiscountDialogComponent} from './settings/apps/parts/apps-upsert/parts/discount-dialog/discount-dialog.component';
import {PricingRuleDialogComponent} from './settings/apps/parts/apps-upsert/parts/pricing-rule-dialog/pricing-rule-dialog.component';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {SettingsTabComponent} from './settings/apps/parts/apps-upsert/parts/settings-tab/settings-tab.component';
import {PricingTabComponent} from './settings/apps/parts/apps-upsert/parts/pricing-tab/pricing-tab.component';
import {LocationsComponent} from './settings/locations/locations.component';
import {LocationUpsertComponent} from './settings/locations/parts/location-upsert/location-upsert.component';
import {LocationImportComponent} from './settings/locations/parts/location-import/location-import.component';
import {MapComponent} from './drivers/map/map.component';
import {LocationPickerComponent, TabSelected} from './settings/locations/parts/location-picker/location-picker.component';
import {FilterComponent} from '../shared/dispatchpanel/parts/filter/filter.component';
import {InvoicesComponent} from './administration/invoices/invoices.component';
import {ToolbarComponent} from './administration/invoices/parts/toolbar/toolbar.component';
import {InstallTpsComponent} from './settings/install-tps/install-tps.component';
import {LocationPoliciesComponent} from './settings/locations/parts/location-policies/location-policies.component';
import {LocationPolicyUpsertComponent} from './settings/locations/parts/location-policy-upsert/location-policy-upsert.component';
import {LocationPolicyTabComponent} from './settings/apps/parts/apps-upsert/parts/location-policy-tab/location-policy-tab.component';
import {DebtorsComponent} from './debtors/debtors.component';
import {CompanyDebtorUpsertComponent} from './debtors/parts/debtor-upsert/company-debtor-upsert.component';
import {CommissionTabComponent} from './settings/apps/parts/apps-upsert/parts/commission-tab/commission-tab.component';
import {DynamicFormModule} from 'app/components/global/dynamic-form/dynamic-form.module';
import {KeepHtmlPipeModule} from '../../pipes/keep-html.pipe';
import {RatingsComponent} from './ratings/ratings.component';
import {DispatchDialogComponent} from '../shared/dispatchpanel/parts/dispatch-dialog/dispatch-dialog.component';
import {RideEditComponent} from '../shared/dispatchpanel/parts/ride-edit/ride-edit.component';
import {RideEditLocationComponent} from '../shared/dispatchpanel/parts/ride-edit/parts/ride-edit-location/ride-edit-location.component';
import {RideEditAddressComponent} from '../shared/dispatchpanel/parts/ride-edit/parts/ride-edit-address/ride-edit-address.component';
import {RideEditRequesteddateComponent} from '../shared/dispatchpanel/parts/ride-edit/parts/ride-edit-requesteddate/ride-edit-requesteddate.component';
import {RideEditPaymentmethodComponent} from '../shared/dispatchpanel/parts/ride-edit/parts/ride-edit-paymentmethod/ride-edit-paymentmethod.component';
import {RideEditPassengerComponent} from '../shared/dispatchpanel/parts/ride-edit/parts/ride-edit-passenger/ride-edit-passenger.component';
import {RideEditPriceComponent} from '../shared/dispatchpanel/parts/ride-edit/parts/ride-edit-price/ride-edit-price.component';
import {RideEditDebtorComponent} from '../shared/dispatchpanel/parts/ride-edit/parts/ride-edit-debtor/ride-edit-debtor.component';
import {RideEditDriverComponent} from '../shared/dispatchpanel/parts/ride-edit/parts/ride-edit-driver/ride-edit-driver.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DirectionsMapWaypointsDirective} from '../../directives/directions-map.waypoints-directive';
import {ReplacePipeModule} from '../../pipes/replace.pipe';
import {RideEditNoteComponent} from '../shared/dispatchpanel/parts/ride-edit/parts/ride-edit-note/ride-edit-note.component';
import {CloudConnectComponent} from './cloud-connect/cloud-connect.component';
import {StripeDialogComponent} from './cloud-connect/parts/stripe-dialog/stripe-dialog.component';
import {LocalizedCurrencyPipeModule} from '../../pipes/localized-currency.pipe';
import {UpcommingInvoiceComponent} from './cloud-connect/parts/upcomming-invoice/upcomming-invoice.component';
import {MainDashboardComponent} from './dashboard/main-dashboard/main-dashboard.component';
import {WebUserUpsertComponent} from './drivers/webuser-upsert/webuser-upsert.component';
import {GoogleApiKeyComponent} from './cloud-connect/parts/google-api-key/google-api-key.component';
import {ContractEditComponent} from './cloud-connect/parts/contract-edit/contract-edit.component';
import {ContractEditDialogComponent} from './cloud-connect/parts/dialog/contract-edit-dialog/contract-edit-dialog.component';
import {ExtendDispatcherDialogComponent} from './cloud-connect/parts/dialog/extend-dispatcher-dialog/extend-dispatcher-dialog.component';
import {UpdateGoogleKeyDialogComponent} from './cloud-connect/parts/dialog/update-google-key-dialog/update-google-key-dialog.component';
import {PromotionsComponent} from './settings/promotions/promotions.component';
import {PromotionUpsertComponent} from './settings/promotions/parts/promotion-upsert/promotion-upsert.component';
import {CouponsComponent} from './settings/coupons/coupons.component';
import {CouponUpsertComponent} from './settings/coupons/parts/coupon-upsert/coupon-upsert.component';
import {PromotionPushDialogComponent} from './settings/promotions/parts/promotion-push-dialog/promotion-push-dialog.component';
import {MatFileUploadModule} from 'angular-material-fileupload';
import {CouponDetailsComponent} from './settings/coupons/parts/coupon-details/coupon-details.component';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {GeneralComponent} from './settings/general/general.component';
import {WebbookerSettingsComponent} from './webbooker/parts/webbooker-settings/webbooker-settings.component';
import {PurchaseWebbookerComponent} from './webbooker/parts/purchase-webbooker/purchase-webbooker.component';
import {WebbookerTabComponent} from './settings/apps/parts/apps-upsert/parts/webbooker-tab/webbooker-tab.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {ChangeOwnerComponent} from './drivers/webuser-upsert/parts/change-owner/change-owner.component';
import {ShareWebbookerComponent} from './webbooker/parts/share-webbooker/share-webbooker.component';
import {WebbookerComponent} from './webbooker/webbooker.component';
import {RatingComponent} from './administration/rides/ride-details/parts/rating/rating.component';
import {WebbookerIntegrationCodeComponent} from './webbooker/parts/webbooker-integration-code/webbooker-integration-code.component';
import {DeleteWebbookerDialogComponent} from './settings/apps/parts/apps-upsert/parts/webbooker-tab/parts/delete-webbooker-dialog/delete-webbooker-dialog.component';
import {CustomerInvoicesComponent} from './administration/customer-invoices/customer-invoices.component';
import {InvoiceSettingsComponent} from './settings/invoice-settings/invoice-settings.component';
import {CustomerUpsertComponent} from './debtors/parts/customer-upsert/customer-upsert.component';
import {RecentJobsComponent} from './drivers/driver-details/parts/recent-jobs/recent-jobs.component';
import {RecentShiftsComponent} from './drivers/driver-details/parts/recent-shifts/recent-shifts.component';
import {MillisecondsToHoursPipeModule} from '../../pipes/milliseconds-to-hours.pipe';
import {CloseProfileComponent} from './settings/general/parts/close-profile/close-profile.component';
import {IcsCalenderComponent} from './drivers/driver-details/parts/ics-calender/ics-calender.component';
import {InstructionVideosComponent} from './about/instruction-videos/instruction-videos.component';
import {ChangeLogComponent} from './about/change-log/change-log.component';
import {MarkdownModule} from 'ngx-markdown';
import {YoutubeVideoComponent} from './about/instruction-videos/parts/youtube-video/youtube-video.component';
import {SafePipeModule} from 'safe-pipe';
import {PriceruleConnectedDaappsComponent} from './settings/pricing/parts/pricing-rules-upsert/parts/pricerule-connected-daapps/pricerule-connected-daapps.component';
import {PaymentOverviewComponent} from './administration/payment-overview/payment-overview.component';
import {UpdatePaymentMethodComponent} from './administration/payment-overview/parts/update-payment-method/update-payment-method.component';
import {UpdateCardDetailsComponent} from './administration/payment-overview/parts/update-card-details/update-card-details.component';
import {OnboardingWizardComponent} from './onboarding-wizard/onboarding-wizard.component';
import {ObWebbookerComponent} from './onboarding-wizard/parts/ob-webbooker/ob-webbooker.component';
import {ObAvailabilityComponent} from './onboarding-wizard/parts/ob-availability/ob-availability.component';
import {ObCalendarComponent} from './onboarding-wizard/parts/ob-calendar/ob-calendar.component';
import {ObServiceComponent} from './onboarding-wizard/parts/ob-service/ob-service.component';
import {ObCompletedComponent} from './onboarding-wizard/parts/ob-completed/ob-completed.component';
import {MatTableModule} from '@angular/material/table';
import {ProductTemplateSelectComponent} from './settings/products/parts/product-template-select/product-template-select.component';
import {JobProcessComponent} from './settings/job-process/job-process.component';
import {AvailabilitySettingsComponent} from './settings/availability-settings/availability-settings.component';
import {ObCalendarSelectComponent} from './onboarding-wizard/parts/ob-calendar/parts/ob-calendar-select/ob-calendar-select.component';
import {AvailabilityOverrideComponent} from './settings/availability-settings/parts/availability-overwrite/availability-override.component';
import {AvailabilityOverrideUpsertComponent} from './settings/availability-settings/parts/availability-overwrite/parts/availability-override-upsert/availability-override-upsert.component';
import {PaymentBalanceRecordAddComponent} from './administration/rides/ride-details/parts/payment-balance/parts/payment-balance-record-add/payment-balance-record-add.component';
import {ExporterService} from '../../services/exporter.service';
import {RideService} from '../../services/ride.service';
import {DistanceFormatModule} from '../../pipes/distanceFormat.pipe';
import { MessageCenterComponent } from './settings/message-center/message-center.component';
import { MessageCenterRuleUpsertComponent } from './settings/message-center/parts/message-center-rule-upsert/message-center-rule-upsert.component';
import { MessageRuleActionsUpsertComponent } from './settings/message-center/parts/message-center-rule-upsert/parts/message-rule-actions-upsert/message-rule-actions-upsert.component';
import { MentionModule } from 'angular-mentions';
import { AddNewMessageComponent } from './settings/message-center/parts/add-new-message/add-new-message.component';
import { AddConditionWizardDialogComponent } from './settings/message-center/parts/message-center-rule-upsert/parts/add-condition-wizard-dialog/add-condition-wizard-dialog.component';
import { MessageRuleConditionsUpsertTypeDialogComponent } from './settings/message-center/parts/message-center-rule-upsert/parts/message-rule-conditions-upsert-type-dialog/message-rule-condition-upsert-type-dialog.component';
import { MessageRuleActionEditDialogSmsComponent } from './settings/message-center/parts/message-center-rule-upsert/parts/message-rule-actions-upsert/parts/message-rule-action-edit-dialog-sms/message-rule-action-edit-dialog-sms.component';
import { MessageRuleActionEditDialogEmailComponent } from './settings/message-center/parts/message-center-rule-upsert/parts/message-rule-actions-upsert/parts/message-rule-action-edit-dialog-email/message-rule-action-edit-dialog-email.component';
import {QuillModule} from 'ngx-quill';
import {ExportsComponent} from './administration/exports/exports.component';
import { PricerulePassengerMultiplierRatioComponent } from './settings/pricing/parts/pricing-rules-upsert/parts/pricerule-passenger-multiplier-ratio/pricerule-passenger-multiplier-ratio.component';
import { MessageRuleAddWizardComponent } from './settings/message-center/parts/message-center-rule-upsert/parts/message-rule-add-wizard/message-rule-add-wizard.component';
import { MessageRuleActionAddNewDialogComponent } from './settings/message-center/parts/message-center-rule-upsert/parts/message-rule-actions-upsert/parts/message-rule-action-add-new-dialog/message-rule-action-add-new-dialog.component';
import { ServiceAreaComponent } from './settings/service-area/service-area.component';
import { ObServiceareaComponent } from './onboarding-wizard/parts/ob-servicearea/ob-servicearea.component';
import { CancelPurchaseDialogComponent } from './cloud-connect/parts/cancel-purchase-dialog/cancel-purchase-dialog.component';
import {MatBadgeModule} from '@angular/material/badge';
import {NgxMatDatetimePickerModule} from '@angular-material-components/datetime-picker';
import { ProductSelectImageDialogComponent } from './settings/products/parts/product-upsert/parts/product-select-image-dialog/product-select-image-dialog.component';
import { JobMetaFieldsComponent } from './settings/job-meta-fields/job-meta-fields.component';
import { JobMetaFieldUpsertComponent } from './settings/job-meta-fields/parts/job-meta-field-upsert/job-meta-field-upsert.component';
import { JobmetafieldConnectedDappsComponent } from './settings/job-meta-fields/parts/jobmetafield-connected-dapps/jobmetafield-connected-dapps.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'https://localization-api.dispatchapi.io/api/Projects/591afd8a17f51db67fc57968/', '');
}

@NgModule({
  imports: [
    SafePipeModule,
    CommonModule,
    SharedModule,
    UcFirstPipeModule,
    ReplacePipeModule,
    KeepHtmlPipeModule,
    AgmCoreModule.forRoot(),
    QuillModule.forRoot(),
    CovalentMessageModule,
    CovalentLayoutModule,
    CovalentLoadingModule,
    CovalentMediaModule,
    CovalentMenuModule,
    CovalentDataTableModule,
    CovalentSearchModule,
    CovalentPagingModule,
    CovalentExpansionPanelModule,
    CovalentMarkdownModule,
    MatSnackBarModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSidenavModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatTabsModule,
    MatRadioModule,
    MatGridListModule,
    MatDialogModule,
    CompanyRoutingModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    WidgetsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatFileUploadModule,
    ColorPickerModule,
    DynamicFormModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MatButtonToggleModule,
    AutocompleteLibModule,
    FontAwesomeModule,
    LocalizedCurrencyPipeModule,
    NgxIntlTelInputModule,
    MillisecondsToHoursPipeModule,
    MarkdownModule.forRoot(),
    MatTableModule,
    NgxLoadingButtonsModule,
    DistanceFormatModule,
    MentionModule,
    TabSelected,
    MatBadgeModule,
    NgxMatDatetimePickerModule
  ],
  providers: [
    AuthService,
    UserService,
    GoogleMapsAPIWrapper,
    RideService,
    ExporterService
  ],
  declarations: [
    DashboardComponent,
    DispatchpanelComponent,
    RideDetailsComponent,
    DriverUpsertComponent,
    DriverDetailsComponent,
    RideLogComponent,
    RideDispatchLogComponent,
    RideDispatchLogMapComponent,
    ProductsComponent,
    PricingComponent,
    ProductUpsertComponent,
    CollectionComponent,
    PricingRulesUpsertComponent,
    TopComponent,
    TableComponent,
    ColumnComponent,
    LocationColumnComponent,
    AppsComponent,
    AppsUpsertComponent,
    PricingDiscountsUpsertComponent,
    TimeframesComponent,
    DiscountDialogComponent,
    PricingRuleDialogComponent,
    DispatchDialogComponent,
    SettingsTabComponent,
    PricingTabComponent,
    LocationsComponent,
    LocationUpsertComponent,
    MapComponent,
    LocationPickerComponent,
    LocationsComponent,
    LocationImportComponent,
    MapComponent,
    LocationPickerComponent,
    FilterComponent,
    InvoicesComponent,
    ToolbarComponent,
    InstallTpsComponent,
    LocationPoliciesComponent,
    LocationPolicyUpsertComponent,
    LocationPolicyTabComponent,
    DebtorsComponent,
    CompanyDebtorUpsertComponent,
    CommissionTabComponent,
    RatingsComponent,
    DispatchDialogComponent,
    RideEditComponent,
    RideEditLocationComponent,
    RideEditAddressComponent,
    RideEditRequesteddateComponent,
    RideEditPaymentmethodComponent,
    RideEditPassengerComponent,
    RideEditPriceComponent,
    RideEditDebtorComponent,
    RideEditDriverComponent,
    DirectionsMapWaypointsDirective,
    RideEditNoteComponent,
    CloudConnectComponent,
    StripeDialogComponent,
    UpcommingInvoiceComponent,
    MainDashboardComponent,
    WebUserUpsertComponent,
    GoogleApiKeyComponent,
    ContractEditComponent,
    ContractEditDialogComponent,
    ExtendDispatcherDialogComponent,
    UpdateGoogleKeyDialogComponent,
    PromotionsComponent,
    PromotionUpsertComponent,
    CouponsComponent,
    CouponUpsertComponent,
    PromotionPushDialogComponent,
    CouponDetailsComponent,
    GeneralComponent,
    WebbookerSettingsComponent,
    PurchaseWebbookerComponent,
    WebbookerTabComponent,
    ChangeOwnerComponent,
    ShareWebbookerComponent,
    WebbookerComponent,
    RatingComponent,
    WebbookerIntegrationCodeComponent,
    DeleteWebbookerDialogComponent,
    CustomerInvoicesComponent,
    InvoiceSettingsComponent,
    CustomerUpsertComponent,
    IcsCalenderComponent,
    RecentJobsComponent,
    RecentShiftsComponent,
    CloseProfileComponent,
    InstructionVideosComponent,
    ChangeLogComponent,
    YoutubeVideoComponent,
    PriceruleConnectedDaappsComponent,
    PaymentOverviewComponent,
    UpdatePaymentMethodComponent,
    UpdateCardDetailsComponent,
    OnboardingWizardComponent,
    ObWebbookerComponent,
    ObAvailabilityComponent,
    ObCalendarComponent,
    ObServiceComponent,
    ObCompletedComponent,
    ProductTemplateSelectComponent,
    JobProcessComponent,
    AvailabilitySettingsComponent,
    ObCalendarSelectComponent,
    AvailabilityOverrideComponent,
    AvailabilityOverrideUpsertComponent,
    PaymentBalanceRecordAddComponent,
    MessageCenterComponent,
    MessageCenterRuleUpsertComponent,
    MessageRuleActionsUpsertComponent,
    AddNewMessageComponent,
    AddConditionWizardDialogComponent,
    MessageRuleConditionsUpsertTypeDialogComponent,
    MessageRuleActionEditDialogSmsComponent,
    MessageRuleActionEditDialogEmailComponent,
    ExportsComponent,
    PricerulePassengerMultiplierRatioComponent,
    MessageRuleAddWizardComponent,
    MessageRuleActionAddNewDialogComponent,
    ServiceAreaComponent,
    ObServiceareaComponent,
    CancelPurchaseDialogComponent,
    ProductSelectImageDialogComponent,
    JobMetaFieldsComponent,
    JobMetaFieldUpsertComponent,
    JobmetafieldConnectedDappsComponent
  ],
  exports: []
})
export class CompanyModule {
}
