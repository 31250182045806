import {Address} from './address';
import {Status} from './status';

export class JobMeta {
  id: string;
  name: string;
  fieldType: string;
  min?: number;
  max?: number;
  surcharge?: number;
  surchargeReturn?: number;
  hiddenValue?: string;
  options?: any[];
  DaAppInstalls?: any[];
  status: string;
  created?: string;
  modified?: string;
  color: string;
  placeholder: string;
  helptext: string;

  constructor() {
    this.name = '';
    this.fieldType = 'text';
    this.color = '#000000';
    this.min = 0;
    this.max = 0;
    this.surcharge = 0;
    this.surchargeReturn = 0;
    this.options = [];
    this.DaAppInstalls = [];
  }
}
